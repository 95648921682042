import React, { useState } from "react";
import { useStaticQuery, graphql } from "gatsby"

import { Tabs, TabList, Tab, TabPanels, TabPanel } from "@reach/tabs";
import { Dialog } from "@reach/dialog";
import "@reach/dialog/styles.css";
import VisuallyHidden from "@reach/visually-hidden";

import Swiper from "react-id-swiper";
// import "react-id-swiper/lib/styles/css/swiper.css";

import {default as GatsbyImage} from 'gatsby-image';
import Fuse from 'fuse.js';
import {cloneDeep, flatten, filter} from 'lodash';

import MyMap from '../mymap/mymap';
import MapSelectedStop from '../map_selected_stop/mapselectedstop';


const MainObjects = (props) => {

    let language_id = props.lang_id || 2;
    language_id += "";
    
    // get main objects data
    const data = useStaticQuery(MAIN_OBJECTS_QUERY)
    const FG_BASE_URL = "https://virtulleum.fluxguide.com/fluxguide/";
    
    // let categories = data.categoryStops.categories;
    let exhibition = data.categoryStops.exhibition;

    // TODO: filter categories and exhibition by language
    let categories = cloneDeep(data.categoryStops.categories);
    // categories = categories.filter(el => el.language_id === language_id);

    if(language_id == 1){
        categories.forEach(cat => {
            cat.category_title = cat.category_title_en;
        }); 
    }

    let all_stops = filter( flatten(categories.map(function(category) { return category.stops})), function(stop){ return stop.language_id == language_id});

    categories.forEach(cat => {
        let cat_stops = filter( cat.stops, function(stop){ return stop.language_id == language_id});
        cat.stops = cat_stops;
    });

    let [filteredCategories, setFilteredCategories] = useState(categories);


    // local state 
    const [showDialog, setShowDialog] = useState(false);
    const [selectedStop, setSelectedStop] = useState(null);
    const [activeTabIndex, setActiveTabIndex] = useState(0);

    // click on stop -> set selected stop and open dialog
    let mainObjectClick = my_selected_stop => {
        setSelectedStop(my_selected_stop);
        setShowDialog(true);
    };

    // on tabs changed -> invalidate size for map
    let onTabsChanged = (activeTabIndex) => {
        setActiveTabIndex(activeTabIndex);
    }


    const swiperParams = {
        pagination: {
            el: ".swiper-pagination",
            type: "bullets"
            // clickable: true
        },
        loop: true,
        autoplay: {
            delay: 3000
        },
        zoom: {
            maxRatio: 3
        }
    };

    // intialize fuse
    var fuse_options = {
        shouldSort: true,
        threshold: 0.3,
        location: 0,
        distance: 300,
        maxPatternLength: 32,
        minMatchCharLength: 1,
        keys: [
          "title",
          "public_number"
        ]
      };
    var fuse = new Fuse(all_stops, fuse_options);


    /**
     * Search through all stops
     */
    let search = function(event) {
        let search_term = event.currentTarget.value;

        if(search_term.length > 0) {
            var result_stops = fuse.search(search_term);
            var result_stops_ids = result_stops.map(function(s) { return s.id });

            var _filteredCategories = categories.map(function(category) {
                category.stops = category.stops.filter(function(stop) {
                    if( result_stops_ids.indexOf(stop.id) !== -1 ) return true;
                    else return false;
                });
                return category;
            })

            _filteredCategories = _filteredCategories.filter(function(category) {
                return category.stops.length > 0;
            })

            setFilteredCategories(_filteredCategories);
        }
        else {
            setFilteredCategories(categories);
        }
    }


    const translations = {
        list : {
            1: "List",
            2: "Liste"
        },
        map : {
            1: "Map",
            2: "Karte"
        },
        search : {
            1: "Search",
            2: "Suche"
        },
        no_search_results : {
            1: "No search results",
            2: "Keine Suchergebnisse gefunden"
        },
        stationnumber : {
            1: "Station Number",
            2: "Stationsnummer"
        },
        look_up_in_app : {
            1: "Why? Look in the app.",
            2: "Warum? Schauen Sie in der App nach."
        },
        address : {
            1: "ADDRESS",
            2: "ADRESSE"
        },
        opening_hours : {
            1: "OPENING HOURS",
            2: "ÖFFNUNGSZEITEN"
        },
        prices : {
            1: "PRICES",
            2: "PREISE"
        } 
    }


    return (
        <>
            <Tabs onChange={onTabsChanged}>
                <TabList>
                    <Tab>{translations.list[language_id]}</Tab>
                    <Tab>{translations.map[language_id]}</Tab>
                </TabList>
                <input type="search" placeholder={translations.search[language_id]} onChange={search} className="search_input"/>
                <TabPanels>
                    <TabPanel>
                        
                        {filteredCategories.length === 0 && <p>{translations.no_search_results[language_id]}</p> }

                        {filteredCategories.map(category => (
                            <div
                                key={category.category_id}
                                className={`main_object_category cat_${category.category_id}`}
                            >
                                <h4>{category.category_title}</h4>

                                {category.stops.map((stop, index) => (
                                    <button
                                        key={stop.id}
                                        className="main_object_btn"
                                        onClick={() => {
                                            mainObjectClick(stop);
                                        }}
                                    >
                                        <div className="dice_wrapper">
                                            {/* <img
                                                alt={stop.title}
                                                src={stop.virtulleum_website_image_thumb}
                                            /> */}
                                           {stop.virtulleumWebsiteImageThumb.childImageSharp && <GatsbyImage alt={stop.title} fadeIn={false} imgStyle={{objectFit: 'contain', width: "80%", height: "80%", marginTop: "10%", marginLeft: "10%"}} fluid={stop.virtulleumWebsiteImageThumb.childImageSharp.fluid}/> }

                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="9.5"
                                                height="17"
                                                viewBox="0 0 9.5 17"
                                                className="arrow_left_icon"
                                            >
                                                <path
                                                    d="M8,.5.5,8,8,15.5"
                                                    transform="translate(0.5 0.5)"
                                                />
                                            </svg>
                                        </div>
                                        <h5>{stop.virtulleum_short_title}</h5>
                                    </button>
                                ))}
                            </div>
                        ))}
                    </TabPanel>

                    <TabPanel>
                        <MyMap activeTabIndex={activeTabIndex} mainObjectClick={mainObjectClick} exhibition={exhibition} categories={filteredCategories} lang={language_id}/>
                    </TabPanel>

                </TabPanels>
            </Tabs>

            {selectedStop && (
                <Dialog
                    isOpen={showDialog}
                    onDismiss={() => setShowDialog(false)}
                >
                    <button
                        className="close-button"
                        onClick={() => setShowDialog(false)}
                    >
                        <VisuallyHidden>Close</VisuallyHidden>
                        <span aria-hidden>×</span>
                    </button>

                    {selectedStop.virtulleum_website_images.length === 1 && (
                        <div className="swiper_image">
                            {/* <img
                                src={selectedStop.virtulleum_website_images}
                                alt=""
                            /> */}
                            <img src={selectedStop.virtulleum_website_images[0].url} alt="" /> 
                            {/* <GatsbyImage fadeIn={false} fluid={selectedStop.virtulleum_website_images[0].virtulleumWebsiteImages.childImageSharp.fluid}/> */}
                        </div>
                    )}

                    {selectedStop.virtulleum_website_images.length > 1 && (
                        <Swiper {...swiperParams}>
                            {selectedStop.virtulleum_website_images.map(
                                (image, index) => (
                                    <div key={index}>
                                        <img src={image.url} alt="" /> 

                                    {/* // <div key={index}>
                                    //     <GatsbyImage fadeIn={false} fluid={image.virtulleumWebsiteImages.childImageSharp.fluid}/>
                                    // </div> */}
                                    </div>
                                )
                            )}
                        </Swiper>
                    )}

                    <section className="dialog_description">
                        <h3>{selectedStop.title}</h3>
                        <h5>{translations.stationnumber[language_id]} - {selectedStop.public_number}</h5>
                        <div
                            className="description"
                            dangerouslySetInnerHTML={{
                                __html: selectedStop.description
                            }}
                        ></div>

                        <p>{translations.look_up_in_app[language_id]}</p>

                        {selectedStop.description_more !== "" && (
                            <div className="address">
                                <label>{translations.address[language_id]}</label>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: selectedStop.description_more
                                    }}
                                ></div>
                            </div>
                        )}
                        {selectedStop.virtulleum_opening_hours_info !== "" && (
                            <div className="opening_hours">
                                <label>{translations.opening_hours[language_id]}</label>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            selectedStop.virtulleum_opening_hours_info
                                    }}
                                ></div>
                            </div>
                        )}
                        {selectedStop.virtulleum_price_info !== "" && (
                            <div className="price_info">
                                <label>{translations.prices[language_id]}</label>
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html:
                                            selectedStop.virtulleum_price_info
                                    }}
                                ></div>
                            </div>
                        )}
                        
                        <MapSelectedStop selectedStop={selectedStop} exhibition={exhibition} lang={language_id}/>
                    </section>
                </Dialog>
            )}
        </>
    );

};

export default MainObjects;




const MAIN_OBJECTS_QUERY = graphql`
query MainObjectsQuery {
    categoryStops {
        categories {
            category_id
            category_title
            category_title_en 
            language_id
            stops {
                title
                language_id: sys_languages_id
                public_number
                description
                description_more
                geoposition
                categories_id
                id: alternative_id
                virtulleum_price_info
                virtulleum_opening_hours_info
                virtulleum_short_title
                virtulleum_website_images {
                    url
                }
                virtulleumWebsiteImageThumb {
                    childImageSharp {
                        fluid {
                            ...GatsbyImageSharpFluid_withWebp_noBase64
                        }
                    }
                }
            }
        }
        exhibition {
            custom_openstreetmap_url
            geo_startpoint_latlng
            geo_bounds_southwest_latlng
            geo_bounds_northeast_latlng
        }
    }
}
`




// const MAIN_OBJECTS_QUERY = graphql`
// query MainObjectsQuery {
//     categoryStops {
//         categories {
//             category_id
//             category_title 
//             language_id
//             stops {
//                 title
//                 language_id: sys_languages_id
//                 public_number
//                 description
//                 description_more
//                 geoposition
//                 categories_id
//                 id: alternative_id
//                 virtulleum_price_info
//                 virtulleum_opening_hours_info
//                 virtulleum_short_title
//                 virtulleumWebsiteImageThumb {
//                     childImageSharp {
//                         fluid {
//                             ...GatsbyImageSharpFluid_withWebp_noBase64
//                         }
//                     }
//                 }
//                 virtulleum_website_images {
//                     virtulleumWebsiteImages {
//                         childImageSharp {
//                             fluid {
//                                 ...GatsbyImageSharpFluid_withWebp_noBase64
//                             }
//                         }
//                     }
//                 }
//             }
//         }
//         exhibition {
//             custom_openstreetmap_url
//             geo_startpoint_latlng
//             geo_bounds_southwest_latlng
//             geo_bounds_northeast_latlng
//         }
//     }
// }
// `
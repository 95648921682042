import React from "react";
import { Map, TileLayer, Marker } from 'react-leaflet'
import  L  from 'leaflet';


const MapSelectedStop = (props) => {

    let exhibition = props.exhibition;
    let selectedStop = props.selectedStop;

    let position = exhibition.geo_startpoint_latlng;
    
    var pos = selectedStop.geoposition.split(",").map(parseFloat);
    var myIconHtml = `<img src="/images/${selectedStop.public_number}.svg" alt="" />`;
    selectedStop.categories = selectedStop.categories_id.split(";;");

    var myIcon = L.divIcon({
        className: `my-div-icon cat_${selectedStop.categories[0]}`,
        html: myIconHtml,
        iconSize: [40, 40],
        iconAnchor: [20, 20]
    });

    return (
        <Map
            className="map_selected_stop"
            center={pos}
            zoom={15}
            // ref={mapElementRef}
            maxBounds={[
                exhibition.geo_bounds_southwest_latlng,
                exhibition.geo_bounds_northeast_latlng
            ]}
        >
            <TileLayer
                attribution='&copy; <a target="_blank"  rel="noopener" href="http://osm.org/copyright">OpenStreetMap</a> contributors, <a target="_blank" rel="noopener" href="https://www.mapbox.com/about/maps/">&copy; Mapbox</a>'
                url={exhibition.custom_openstreetmap_url}
                maxZoom={19}
                minZoom={8}
            />

            
            <Marker
                position={pos}
                icon={myIcon}
            ></Marker>
                    
        </Map>
    );
}

export default MapSelectedStop;
import React, { useState } from "react";

import AccordionElement from "./accordion_element";

const Accordion = props => {
    let children = props.children;

    let allowOnlyOneOpen = props.allowOnlyOneOpen || false;    

    let initialOpenSections = children.map(child => {
        return child.props.defaultOpen ? false : false
    });

    let [openSections, setOpenSections] = useState(initialOpenSections);


    let onClick = (index) => {

        setOpenSections(prevState => {
            // copy old state
            let newState = [...prevState];

            // set all to false if allowOnlyOneOpen is true
            if (allowOnlyOneOpen === true) {
                newState = newState.map((element, i) => {
                    // do not change the current item
                    if (i === index) return element;
                    else return false;
                });
            }

            // set current item
            newState[index] = !newState[index];

            // trigger onChange
            if(typeof props.onChange === "function") props.onChange({changeIndex: index, changedTo: newState[index], state: newState});

            return newState;
        });
    };

    return (
        <div className="accordion">
            {children.map((child, index) => (
                <AccordionElement
                    key={index}
                    isOpen={openSections[index]}
                    title={child.props.title}
                    onClick={() => onClick(index)}
                    id={child.props.id}
                >
                    {child.props.children}
                </AccordionElement>
            ))}
        </div>
    );
};

export default Accordion;

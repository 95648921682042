import React from "react";

import uuid from './../../utils/uuid'

const AccordionElement = props => {

    const accordion_id = props.id || uuid();

    return (

        <article 
            className="accordion_article" 
            >
            <button 
                className="accordion_title" 
                onClick={props.onClick}
                aria-expanded={props.isOpen} 
                aria-controls={"accordion_content_".concat(accordion_id)}
                id={"accordion_button_".concat(accordion_id)}
                >
                    <h3>{props.title}</h3>
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="9.5"
                        height="17"
                        viewBox="0 0 9.5 17"
                        className="arrow_left_icon"
                    >
                        <path d="M8,.5.5,8,8,15.5" transform="translate(0.5 0.5)" />
                    </svg>
            </button>
            <div 
                className="accordion_content" 
                role="region"
                aria-labelledby={"accordion_button_".concat(accordion_id)}
                id={"accordion_content_".concat(accordion_id)}>
                    {props.children}
            </div>
        </article>
    );
};

export default AccordionElement;

import React, { useRef } from "react";
import { Map, TileLayer, Marker } from 'react-leaflet'
import  L  from 'leaflet';

const MyMap = ({exhibition, categories, mainObjectClick, refresh, lang}) => {
    let position = exhibition.geo_startpoint_latlng;
    
    // ref for map element
    const mapElementRef = useRef(null);

    if(typeof window !== "undefined") {
        window.setTimeout(() => {
            mapElementRef.current.leafletElement.invalidateSize();
        }, 20)
    }
    else {
        return null;
    }

    return (
        <Map
            center={position}
            zoom={15}
            ref={mapElementRef}
            maxBounds={[
                exhibition.geo_bounds_southwest_latlng,
                exhibition.geo_bounds_northeast_latlng
            ]}
        >
            <TileLayer
                attribution='&copy; <a target="_blank"  rel="noopener" href="http://osm.org/copyright">OpenStreetMap</a> contributors, <a target="_blank" rel="noopener" href="https://www.mapbox.com/about/maps/">&copy; Mapbox</a>'
                url={exhibition.custom_openstreetmap_url}
                maxZoom={19}
                minZoom={8}
            />

            {categories.map(category => {
                let all_markers = category.stops.map((stop, index) => {
                    var pos = stop.geoposition.split(",").map(parseFloat);

                    var myIconHtml = `<img src="/images/${stop.public_number}.svg" alt="" />`;

                    var myIcon = L.divIcon({
                        className: `my-div-icon cat_${category.category_id}`,
                        html: myIconHtml,
                        iconSize: [40, 40],
                        iconAnchor: [20, 20]
                    });

                    return (
                        <Marker
                            key={index}
                            position={pos}
                            icon={myIcon}
                            onClick={() => mainObjectClick(stop)}
                        ></Marker>
                    );
                });
                return all_markers;
            })}
        </Map>
    );
}

export default MyMap;